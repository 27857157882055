import React from "react";

const Lomake = () => {
    return ( 
        <div className="Lomake">

        </div>
     );
}
 
export default Lomake;