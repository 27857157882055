
import Lomake from '../../Componenets/Lomake/lomake';


const LomakePage = () => {
    return (

    <>

    <Lomake/>

    </>



    );
}

export default LomakePage;