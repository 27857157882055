
import EULA from '../../Componenets/EULA/Eula';


const EULAsivu = () => {
    return (

    <>
   
    <EULA/>
    
    </>



    );
}

export default EULAsivu;