
import Oikeudellisettiedot from '../../Componenets/Oikeudellisettiedot/Oikeudellisettiedot';

const OikeudellisettiedotSivu = () => {
    return (

    <>
    
    <Oikeudellisettiedot/>
    
    </>



    );
}

export default OikeudellisettiedotSivu;