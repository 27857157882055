import Navbar from '../../Componenets/navbar/navbar';
import NotFound from '../../Componenets/NotFound/404';


const EULAsivu = () => {
    return (

    <>
    <Navbar/>
    <NotFound/>
    
    </>



    );
}

export default EULAsivu;