import Contact from "../../Componenets/Contact/Contact";
import Footer2 from "../../Componenets/Footer/Footer2";
import Navbar2 from "../../Componenets/navbar/Navbar2";

const ContactPage = () => {
    return ( 
        <>
        <Navbar2/>
        <Contact/>
        <Footer2/>
        </>
     );
}
 
export default ContactPage;