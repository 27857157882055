import React from 'react';
import './oikeudellisettiedot.css';

function Oikeudellisettiedot() {
  return (
    <div className="joku">
    <h1 className="oikeuspaaotsikko">AutoHeaven - Yrityksen Oikeudelliset Tiedot</h1>
    
    </div>
  );
}

export default Oikeudellisettiedot;