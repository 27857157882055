import Kayttoehdot from '../../Componenets/Kayttoehdot/Kayttoehdot';
import Footer2 from "../../Componenets/Footer/Footer2";
import Navbar2 from "../../Componenets/navbar/Navbar2";

const KayttoehdotPage = () => {
    return (
    <>
    <Navbar2/>
    <Kayttoehdot/>
    <Footer2/>
    </>
    );
}

export default KayttoehdotPage;