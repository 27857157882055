import React from 'react';
import './eula.css';

function Eula() {
  return (
    <div className="EulaSivu">
      
        <div className='Eulalaatikko'>
          <h1 className="eulaotsikko">AUTOHEAVEN AUTOPESULA - LOPPUKÄYTTÄJÄN OHJELMAN KÄYTTÖEHTOSOPIMUS</h1>
        </div>
      
    </div>
  );
}

export default Eula;
