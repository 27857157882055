
import Tietosuoja from '../../Componenets/Tietosuojakaytanto/Tietosuojakaytanto';
import Footer2 from "../../Componenets/Footer/Footer2";
import Navbar2 from "../../Componenets/navbar/Navbar2";

const TietosuojakaytantoPage = () => {
    return (
    <>
    <Navbar2/>
    <Tietosuoja/>
    <Footer2/>
    </>
    );
}

export default TietosuojakaytantoPage;